<template>
  <CyAlert
    v-if="activeTip"
    v-bind="$attrs"
    closeable
    :title="activeTip.title"
    :content="activeTip.content"
    @close="close"/>
</template>

<script>
export const DEFAULT_DAYS = 5
export const DEFAULT_LS_PATH = 'tips/random'

export default {
  name: 'CyRandomTip',
  props: {
    lsPath: {
      type: String,
      default: DEFAULT_LS_PATH,
    },
    renewalInterval: {
      type: Object,
      default: () => ({ days: DEFAULT_DAYS }),
    },
    tips: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    activeTip: null,
  }),
  mounted () {
    this.setActiveTip()
  },
  methods: {
    close () {
      this.activeTip = null
      localStorage.setItem(`${this.lsPath}/dismissal-date`, Date.now())
    },
    setActiveTip () {
      const randomTip = this.tips[_.random(0, this.tips.length - 1)]
      const lsTipsDismissalDate = parseInt(localStorage.getItem(`${this.lsPath}/dismissal-date`))
      const renewalDate = $date.add(lsTipsDismissalDate || Date.now(), this.renewalInterval)

      if (isNaN(lsTipsDismissalDate) || $date.isAfter(Date.now(), renewalDate)) {
        this.activeTip = randomTip
      }
    },
  },
}
</script>
