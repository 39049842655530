<template>
  <div
    data-cy="emission-equivalence"
    :class="['emission-equivalence', { dark }]">
    <v-icon
      class="emission-equivalence__icon mr-4"
      :size="iconSize">
      flight
    </v-icon>
    <div
      v-if="simplifiedText"
      class="emission-equivalence__text">
      <i18n
        data-cy="text"
        path="flightEquivalenceSimplified">
        <template #flights>
          <div
            v-if="flights.count >= 1"
            class="text-flights">
            {{ $tc('nFlights', flights.count, { n: flights.count }) }}
          </div>
          <div
            v-else
            class="text-flights">
            {{ _.round(flights.count * 100) }}%
          </div>
        </template>
        <template #fromText>
          <div
            class="text-from"
            v-text="$t('from')"/>
        </template>
        <template #from>
          {{ $t(`cities.${flights.from}`) }}
        </template>
        <template #to>
          {{ $t(`cities.${flights.to}`) }}
        </template>
      </i18n>
    </div>

    <div
      v-else
      class="emission-equivalence__text">
      <i18n
        v-if="value"
        data-cy="text"
        :path="flights.count >= 1 ? 'flightEquivalence' : 'flightPercentageEquivalence'">
        <strong v-if="flights.count >= 1">
          {{ $tc('nFlights', flights.count, { n: flights.count }) }}
        </strong>
        <strong v-else>
          {{ _.round(flights.count * 100) }}%
        </strong>
        <strong>
          {{ $t(`cities.${flights.from}`) }}
        </strong>
        <strong>
          {{ $t(`cities.${flights.to}`) }}
        </strong>
      </i18n>
      <span
        v-else
        data-cy="text"
        v-text="$t('noEmissions')"/>
    </div>
  </div>
</template>

<script>
import { FLIGHT_TABLE } from '@/utils/helpers/emission-equivalence'

export default {
  name: 'CyCloudCostManagementEmissionEquivalence',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
    simplifiedText: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '42',
    },
  },
  computed: {
    flights () {
      const [count, from, to] = this.getFlights()
      return { count, from, to }
    },
  },
  methods: {
    getFlights () {
      for (const { from, to, emissions } of FLIGHT_TABLE) {
        if (_.inRange(this.value, emissions * 0.9, emissions * 1.1)) return [1, from, to]
        if (this.value > emissions && this.value % emissions < emissions * 0.2) return [_.round(this.value / emissions), from, to]
      }
      return [_.round(this.value / 100, 2), _.last(FLIGHT_TABLE).from, _.last(FLIGHT_TABLE).to]
    },
  },
  i18n: {
    messages: {
      en: {
        flightEquivalence: 'Current cloud carbon emissions are equal to taking {0} from {1} to {2}.',
        flightEquivalenceSimplified: '{flights} {fromText} {from} to {to}',
        flightPercentageEquivalence: 'Current cloud carbon emissions are equal to flying {0} of the way from {1} to {2}.',
        noEmissions: 'Congratulations, your cloud carbon emissions are zero, your metaphorical plane is still on the tarmac !',
        nFlights: '{n} flights | {n} flight | {n} flights',
      },
      es: {
        flightEquivalence: 'Las emisiones actuales de carbono en la cloud equivalen a tomar {0} desde {1} hasta {2}.',
        flightEquivalenceSimplified: '{flights} {fromText} {from} hasta {to}',
        flightPercentageEquivalence: 'Las emisiones actuales de carbono en la cloud equivalen a volar el {0} del camino de {1} a {2}.',
        noEmissions: '¡Felicitaciones, sus emisiones de carbono en la cloud son cero, su avión metafórico todavía está en la pista!',
        nFlights: '{n} vuelos | {n} vuelo | {n} vuelos',
      },
      fr: {
        flightEquivalence: 'Les émissions de carbone actuelles du cloud équivalent à prendre {0} de {1} à {2}.',
        flightEquivalenceSimplified: '{flights} {fromText} {from} à {to}',
        flightPercentageEquivalence: `Les émissions de carbone actuelles du cloud équivalent à {0} d'un vol de {1} à {2}.`,
        noEmissions: 'Félicitations, vos émissions de carbone actuelle provenant du cloud sont nulles, votre avion métaphorique est toujours sur le tarmac !',
        nFlights: '{n} vols | {n} vol | {n} vols',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.emission-equivalence {
  color: cy-get-color("primary", "light-2");

  &.dark {
    color: cy-get-color("white");
  }

  .v-icon {
    color: cy-get-color("primary", "light-4");
  }
}
</style>
