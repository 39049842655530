<template>
  <div class="filter-list">
    <div class="filter-list__header px-4 py-2">
      <span class="filter-list__title">
        {{ singleFilter ? $t(_.camelCase(filters[0])) : $t('filter') }}
        <v-chip
          v-if="singleFilter"
          x-small
          class="ml-2 option-count">
          {{ _.get(queryBody, filters[0], []).length }}
        </v-chip>
      </span>
      <CyButton
        variant="tertiary"
        theme="grey"
        sm
        @click="resetFilters">
        {{ $t('forms.clearAll') }}
      </CyButton>
    </div>
    <v-expansion-panels
      v-model="openPanel"
      v-click-outside="closePanel"
      :active-class="singleFilter ? 'elevation-0' : 'elevation-4'"
      :readonly="singleFilter"
      accordion>
      <template v-for="filter in populatedFilters">
        <CyCloudCostManagementSidebarRadioFilter
          v-if="filter === 'resource_tagging'"
          :key="filter"
          :filter-id="filter"
          :title="$t(_.camelCase(filter))"
          :options="[
            { label: $t('taggedResources'), value: 'tagged_resources' },
            { label: $t('untaggedResources'), value: 'untagged_resources' },
          ]"
          :is-single-filter="singleFilter"
          @input="filterInputHandler"/>
        <CyCloudCostManagementSidebarCheckboxFilter
          v-else-if="filter === 'master_accounts'"
          :key="filter"
          :filter-id="filter"
          :title="$t(_.camelCase(filter))"
          :options="masterAccountsOptions"
          :is-single-filter="singleFilter"
          @input="filterInputHandler">
          <template #item="{ props: option }">
            <div class="d-flex">
              <CyIconCredential
                size="24"
                class="mr-4"
                :type="getMasterAccountProvider(option.value)"/>
              {{ option.label }}
            </div>
          </template>
        </CyCloudCostManagementSidebarCheckboxFilter>
        <CyCloudCostManagementSidebarCheckboxFilter
          v-else-if="filter === 'linked_accounts'"
          :key="filter"
          :filter-id="filter"
          :title="$t(_.camelCase(filter))"
          :options="getCheckboxFilterOptions(filter)"
          :is-single-filter="singleFilter"
          @input="filterInputHandler">
          <template #item="{ props: option }">
            <div class="d-flex">
              <CyIconCredential
                size="24"
                class="mr-4"
                :type="getLinkedAccountProvider(option)"/>
              {{ option }}
            </div>
          </template>
        </CyCloudCostManagementSidebarCheckboxFilter>
        <CyCloudCostManagementSidebarCheckboxFilter
          v-else
          :key="filter"
          :filter-id="filter"
          :title="$t(_.camelCase(filter))"
          :options="getCheckboxFilterOptions(filter)"
          :is-single-filter="singleFilter"
          @input="filterInputHandler"/>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CyCloudCostManagementSidebarCheckboxFilter from '@/components/cloud-cost-management/sidebar/checkbox-filter.vue'
import CyCloudCostManagementSidebarRadioFilter from '@/components/cloud-cost-management/sidebar/radio-filter.vue'

export default {
  name: 'CyCloudCostManagementSidebarFilterList',
  components: {
    CyCloudCostManagementSidebarCheckboxFilter,
    CyCloudCostManagementSidebarRadioFilter,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ filters }) => ({
    openPanel: filters.length === 1 ? 0 : null,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      filterValues: (state) => state.filterValues,
      queryBody: (state) => state.queryBody,
    }),
    masterAccountsOptions () {
      const masterAccounts = this.filterValues?.master_accounts_with_cp
      const { providerCanonical } = this.$route.params
      return _.chain(masterAccounts)
        .omitBy((_value, key) => !_.isEmpty(providerCanonical) && providerCanonical !== key)
        .flatMap()
        .uniq()
        .map((value) => ({ label: value, value }))
        .value()
    },
    singleFilter () {
      return this.filters.length === 1
    },
    populatedFilters () {
      const staticValueFilters = ['resource_tagging', 'master_accounts']
      return this.filters.filter((filter) => {
        if (_.includes(staticValueFilters, filter)) return true
        if (!_.isEmpty(this.queryBody[filter])) return true
        if (_.isEmpty(this.filterValues[filter])) return false
        return true
      })
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
    closePanel () {
      if (this.singleFilter) return
      this.openPanel = null
    },
    filterInputHandler () {
      this.closePanel()
      this.$emit('input')
    },
    getCheckboxFilterOptions (filter) {
      const queryBodyOptions = _.get(this.queryBody, filter)
      const filterOptions = _.get(this.filterValues, filter)

      // TODO: FE#6428 make it work with objects
      // issue is atm queryBody works only with array values, as this is what BE expects, so we
      // can't know queryBody full path of selected value as only the value itself is stored in the query
      if (filter === 'tags') return filterOptions || {}

      if (filter === 'environments' && !filterOptions[0]) filterOptions[0] = this.$t('noEnvironments')

      return _.uniq([
        ...(filterOptions || []),
        ...(queryBodyOptions || []),
      ]).sort()
    },
    resetFilters () {
      this.filters.forEach((filter) => this.SET_QUERY_FILTER({ key: filter, value: [] }))
      this.$emit('input')
    },
    getMasterAccountProvider (value) {
      const masterAccounts = this.filterValues?.master_accounts_with_cp
      return _.findKey(masterAccounts, (accountIds) => accountIds.includes(value))
    },
    getLinkedAccountProvider (value) {
      const linkedAccounts = this.filterValues?.linked_accounts_with_cp
      return _.findKey(linkedAccounts, (accountIds) => accountIds.includes(value))
    },
  },
  i18n: {
    messages: {
      en: {
        masterAccounts: 'Master account',
        components: '@:cloudCostManagement.groupBy.category',
        environments: '@:Environment',
        filter: 'Filter',
        linkedAccounts: '@:cloudCostManagement.groupBy.linkedAccount',
        noEnvironments: '@:cloudCostManagement.noEnvironments',
        projects: '@:Project',
        providers: '@:provider',
        regions: '@:untranslated.region',
        services: '@:cloudCostManagement.groupBy.service',
        tags: '@:untranslated.tags',
        resourceTagging: 'Resource tagging',
        taggedResources: 'Tagged resources',
        untaggedResources: 'Untagged resources',
      },
      es: {
        masterAccounts: 'Cuenta principal',
        components: '@:cloudCostManagement.groupBy.category',
        environments: '@:Environment',
        filter: 'Filtro',
        linkedAccounts: '@:cloudCostManagement.groupBy.linkedAccount',
        projects: '@:Project',
        noEnvironments: '@:cloudCostManagement.noEnvironments',
        providers: '@:provider',
        regions: '@:untranslated.region',
        services: '@:cloudCostManagement.groupBy.service',
        tags: '@:untranslated.tags',
        resourceTagging: 'Tags de recursos',
        taggedResources: 'Recursos con tags',
        untaggedResources: 'Recursos sin tags',
      },
      fr: {
        masterAccounts: 'Compte principal',
        components: '@:cloudCostManagement.groupBy.category',
        environments: '@:Environment',
        filter: 'Filtre',
        linkedAccounts: '@:cloudCostManagement.groupBy.linkedAccount',
        projects: '@:Project',
        noEnvironments: '@:cloudCostManagement.noEnvironments',
        providers: '@:provider',
        regions: '@:untranslated.region',
        services: '@:cloudCostManagement.groupBy.service',
        tags: '@:untranslated.tags',
        resourceTagging: 'Marquage des ressources',
        taggedResources: 'Ressources avec tag',
        untaggedResources: 'Ressources sans tag',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .filter-list {
    border: 1px solid cy-get-color("grey");
    border-radius: 4px;

    ::v-deep .v-expansion-panels {
      z-index: 3;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: thin solid cy-get-color("black", $alpha: 0.12);
      border-radius: 4px;
      background-color: cy-get-color("white");
    }

    &__title {
      color: cy-get-color("grey", "dark-2");
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      text-transform: uppercase;

      ::v-deep .v-chip__content {
        font-weight: $font-weight-default;
      }
    }
  }

  .checkbox-filter {
    ::v-deep .credential-icon {
      min-width: 24px;
    }
  }
</style>
