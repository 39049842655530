export const FLIGHT_TABLE = [
  {
    from: 'paris',
    to: 'sydney',
    emissions: 3000,
  },
  {
    from: 'paris',
    to: 'perth',
    emissions: 2500,
  },
  {
    from: 'rome',
    to: 'perth',
    emissions: 2300,
  },
  {
    from: 'losAngeles',
    to: 'sydney',
    emissions: 2000,
  },
  {
    from: 'paris',
    to: 'buenosAires',
    emissions: 1800,
  },
  {
    from: 'paris',
    to: 'sanFrancisco',
    emissions: 1500,
  },
  {
    from: 'paris',
    to: 'houston',
    emissions: 1300,
  },
  {
    from: 'paris',
    to: 'chicago',
    emissions: 1100,
  },
  {
    from: 'paris',
    to: 'newYork',
    emissions: 950,
  },
  {
    from: 'lisbon',
    to: 'boston',
    emissions: 840,
  },
  {
    from: 'athens',
    to: 'reykjavik',
    emissions: 700,
  },
  {
    from: 'madrid',
    to: 'moscow',
    emissions: 585,
  },
  {
    from: 'lisbon',
    to: 'warsaw',
    emissions: 485,
  },
  {
    from: 'paris',
    to: 'istanbul',
    emissions: 400,
  },
  {
    from: 'amsterdam',
    to: 'lisbon',
    emissions: 335,
  },
  {
    from: 'paris',
    to: 'lisbon',
    emissions: 275,
  },
  {
    from: 'london',
    to: 'madrid',
    emissions: 240,
  },
  {
    from: 'paris',
    to: 'barcelona',
    emissions: 200,
  },
  {
    from: 'paris',
    to: 'marseille',
    emissions: 170,
  },
  {
    from: 'paris',
    to: 'bordeaux',
    emissions: 150,
  },
  {
    from: 'paris',
    to: 'london',
    emissions: 122,
  },
  {
    from: 'amsterdam',
    to: 'brussels',
    emissions: 100,
  },
]
